<template>
    <div class="login">
        <SquareLogo class="toplogo" />
        <form class="form">
            <a-form-item>
                <span class="label" slot="label">{{$t('login.label.account')}}</span>
                <a-input class="bg-sec" size="large" v-model="address" :disabled="disableAddressIpt" />
            </a-form-item>
            <a-form-item>
                <span class="label" slot="label">{{$t('login.label.password')}}</span>
                <a-input-password id="password" autocomplete="off" class="bg-sec" size="large" ref="password" v-model="password" />
            </a-form-item>
            <div class="btn-primary mg30" @click="submit">
                {{$t('common.login_btn')}}
            </div>
            <div class="flex color-gray">
                <span @click="forgetPass">{{$t('login.forget')}}</span>
                <span @click="goRegister">{{$t('common.register_btn')}}</span>
            </div>
        </form>
    </div>
</template>
<script>
import SquareLogo from '../components/SquareLogo.vue';
import Shelldao from "../web3/tron/shelldao";

export default {
    name: 'Login',
    data(){
        return {
            address: '',
            password: '',
            disableAddressIpt: true,
            loading: false,
        }
    },
    components:{
        SquareLogo
    },
    mounted(){
        // let _this = this;
        Shelldao.init((res)=>{
            this.address = res.gWalletAddress;
        });
        // let _this = this;
        // let obj = setInterval(async ()=>{
        //     if (window.walletAddress) {
        //         clearInterval(obj)
        //         _this.address = window.walletAddress;
        //     }
        // }, 10);
        this.$refs.password.focus();
        // this.$store.commit('setShowNav', false);
        // this.$store.commit('setShowLogo', true);

        setTimeout(()=>{
            if (this.address == ''){
                this.disableAddressIpt = false
            }
        },5000)
    },
    methods:{
        submit(){
            this.loading = true;
            let _this = this;
            if(!this.address || !this.password){
                this.$message.error(this.$t('login.tips.iptError'));
                return;
            }
            let data = {
                address: this.address,
                password: this.password
            };
            this.$http.call(this.$http.api.login,{data:data}).then(function(res){
                let resp = res.data;
                if(resp.code=='200'){
                    _this.$store.commit('setToken', resp.data.token);
                    _this.$store.commit('setUserinfo', resp.data.user);
                    _this.$router.push({name:'user'});
                }else{
                    _this.$message.error(resp.data);
                } 
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            }).then(()=>{
                _this.loading = false
            });
        },
        goRegister(){
            this.$router.push({name:'register'});
        },
        forgetPass(){
            this.$message.info(this.$t('login.tips.forget'));
        },
    }
}
</script>
<style lang="less" scoped>
.login{
    width: 100%;
    .toplogo {
        padding-top: 256px;
    }
    .form {
        padding: 50px;
        margin: 32px auto;
        width: 90%;
        background: #fae38883;
        border-radius: 30px;
        box-shadow: 1PX 1PX 4PX #fff;
        color: #fff;
        .label {
            color: #fff;
        }
        .color-gray {
            color: #dadada;
        }
    }
}


</style>
